nav ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  display: inline-block;
  position: relative;
  padding: 10px;
  cursor: pointer;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 90%;
  left: 0;
  width: 15em;
  border-radius: 5px;
  background-color: #d6d6d6ef;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

nav ul ul ul {
  left: 100%;
  top: 0;
  font-size: 10px;
}

nav li:hover>ul {
  display: block;
}

nav li ul li {
  display: block;
  width: 150px;
  transition: ease-in 0.1s;

}

nav li ul li:hover {
  border-radius: 5px;
  background-color: #fd4d4d;
}

nav a,
nav span {
  text-decoration: none;
  font-weight: bolder;
  font-size: 18px;
}

nav ul ul a,
nav ul ul span {
  font-size: 15px;
  width: 150%;
  transition: ease-in .2s;

}

nav ul ul a:hover,
nav ul ul span:hover {
  color: white;

}

.navigation {
  height: 100px;
  margin-bottom: 2%;
  margin: auto;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: black;
  font-weight: bold;
  z-index: 1;

}

.navigation img {
  margin-top: -4px;
}

.brand-name {
  text-decoration: none;
  font-size: 1.3rem;
  margin-left: 1.5rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: rgb(0, 0, 0);
}

.navigation-menu li span {
  color: rgb(0, 0, 0);
}

.hamburger {
  border: 0;
  height: 40px;
  width: 10px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.closeNav {
  display: none;
}

@keyframes mymove {
  from {
    left: -150px;
  }

  to {
    left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .navigation {
    width: auto;
    height: 80px;
    box-shadow: 0 4px 8px 0 rgba(8, 4, 4, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }

  nav a,
  nav span {
    text-decoration: none;
    font-weight: bolder;
    font-size: 13px;
  }

  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul {
    position: absolute;
    padding: 20px;
    top: 6rem;
    left: -10px;
    display: flex;
    flex-direction: column;
    width: 60%;
    background-color: #c50000;
    animation: mymove .2s;
    z-index: 1;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
    transition: ease-in 1.5s;
    width: 100%;
    text-align: start;
  }

  .navigation-menu li:nth-child(3):hover~li:nth-child(4) {
    top: 6.5em;
    z-index: 1;
  }

  nav li ul li {
    top: -3.2em;
    width: 130px;
    background-color: blue;
  }

  .navigation-menu li a {
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: start;
  }

  .navigation-menu li span {
    color: rgb(255, 255, 255);

  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu.expanded ul {
    display: block;
    font-size: 20px;
    height: 150em;
  }

  .closeNav {
    display: block;
    position: relative;
    top: -8.5em;
    left: 10em;
  }
}