body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.swiper-slide .card {
  margin: 0 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.swiper-slide .card .starreview {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide .card .starreview span {
  padding: 0 5px;
}

.swiper-slide .card img {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.swiper-slide .card h2 {
  text-align: center;
}

@media only screen and (min-width: 2100px) {
  .swiper-slide:first-child {
    transition: transform 100ms;
  }

  .swiper-slide:first-child img {
    transition: box-shadow 500ms;
  }

  .swiper-slide.swiper-slide-active:first-child {
    transform: translateX(50%);
    z-index: 2;
  }

  .swiper-slide.swiper-slide-active:first-child img {
    box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.35);
  }

  .swiper-slide:nth-child(2) {
    transition: transform 100ms;
  }

  .swiper-slide.swiper-slide-next:nth-child(2) {
    transform: translateX(55%);
    z-index: 1;
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
    transform: translateX(-50%);
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
    transform: translateX(-55%);
  }
}