@media screen and (max-width: 1018px) {

    .slideshow-container {
        margin: auto;
        max-width: 1200px;
        position: relative;
        margin: auto;
    }

    .slideshow {
        width: 100%;
        margin-left: 15px;
    }

    .slideshow .text_slideshow {
        margin-left: 10px;
    }

    .slideshow .slideshow_text .head {
        font-size: 30px;
        margin-left: -18px;
    }

    .slideshow .slideshow_text p {
        width: 100%;
        font-size: 15px;
    }

    .slideshow .slideshow_text h2 {
        font-size: 20px;
    }

    .slideshow img {
        display: none;
        justify-content: center;
    }

    .slideshow .slideshow_text .text_slideshow {
        margin-left: -8.7em;
        text-align: center;
    }

    .welcome_to_ITA {
        font-size: 12px;
        font-weight: bold;
        margin-top: 13em;
        width: 80%;
        text-align: center;
    }

    .welcome_to_ITA p {
        font-size: 12px;
    }

    .frontpage_cards {
        padding: 0 5%;
        width: 90%;
        display: flex;
        flex-direction: column;
        font-weight: bold;
    }

    .empower_para {
        width: 80%;
        font-weight: bold;
    }

    .key_features {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        font-size: 10px;
        margin-bottom: 40rem;
    }

    .key_features .key_features_left {
        text-align: center;
        width: 90%;
    }

    .key_features_left .heading_and_para {
        text-align: center;
    }

    .key_features .key_features_card {
        position: absolute;
        padding: 30px;
        width: 25em;
        height: 500px;
        margin-top: 6em;
        margin-left: -15em;
        border-radius: 5px;
        display: grid;
        place-items: center;
        text-align: center;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(8, 4, 4, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        text-align: center;
    }


    .cheers img {
        margin-top: -3.5em;
        width: 90%;
        margin-left: 23px;
    }

    .our_legacy .legacy_main img {
        display: none;
    }

    .our_legacy {
        width: 90%;
        margin: 5%;
        text-align: center;
        border-radius: 10px;
    }

    .our_legacy .legacy_main {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
    }

    .our_legacy .legacy_main h1 {
        font-size: 15px;
        padding: 10px;
    }

    .our_legacy .legacy_main p {
        font-size: 10px;
        margin-bottom: 3em;
    }

    .job_orientation {
        width: 95%;
    }

    .Job_orientation_container .swippercard img {
        width: 50px;
    }

    .Job_orientation_container .swippercard h2 {
        font-size: 18px;
        font-weight: bold;
    }
}

/* --------------------------------Second page-------------------------------- */

@media screen and (max-width: 768px) {

    .slideshow-container {
        margin: auto;
        max-width: 1200px;
        position: relative;
        margin: auto;
    }

    .slideshow {
        width: 70vw;
        margin-left: 10px;
        text-align: center;
    }

    .slideshow .text_slideshow {
        top: 9.5em;
        margin-left: 10px;
    }

    .slideshow .slideshow_text .head {
        margin-top: 1.5em;
        border-bottom: 5px solid;
        border-left: none;
        font-size: 23px;
        margin-left: 15px;
    }

    .slideshow .slideshow_text p {
        width: 70vw;
        font-size: 15px;
        margin-left: 25%;
    }

    .slideshow .slideshow_text h2 {
        font-size: 20px;
        margin-left: 13%;
    }

    .slideshow img {
        display: none;
        justify-content: center;
    }

    .slideshow .slideshow_text .text_slideshow {
        margin-left: -8.7em;
        text-align: center;
    }

    .Conteiner-3 {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
    }

    .Conteiner-3 .Skill {
        width: 100%;
        font-size: 10px;
        margin: 0 20px;
    }

    .Conteiner-3 .Skill h1 {
        width: 100%;
        font-size: 20px;
        text-align: center;
    }

    .Conteiner-4 .courseImage img {
        display: none;
    }

    .Conteiner-5 .Graphs_Container {

        display: flex;
        flex-direction: column-reverse;
    }

    .Conteiner-5 .Graphs_Container img {
        width: 300px;
        display: flex;
        align-items: center;

    }
}

@media screen and (max-width: 1018px) {


    .Conteiner-1 {
        padding: 10px;
    }

    .start-div {
        width: 80%;
        padding: 5px;
    }

    .Conteiner-1 .start-div .result p {
        text-align: center;
    }

    .KeyFeaturesMainContainer {
        display: flex;
        flex-direction: column;
    }

    .FeaturesContainer {
        display: flex;
        flex-direction: row;
    }

    .KeyFeatures01,
    .KeyFeatures02 {
        padding: 10px 6px;
    }

    .KeyFeatures01 div,
    .KeyFeatures02 div {
        font-size: 10px;
    }

    .KeyFeaturesMainContainer img {
        width: 300px;
        height: 450px;
    }

    .Conteiner-5 .Graphs_Container img {
        margin: auto;
        width: 310px;
        height: 250px;
        display: flex;
        align-items: center;

    }

    .Courses_cards {
        margin-top: 25rem;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .Courses_cards .Conteiner-div01 {
        display: flex;
        flex-direction: column;
        margin-bottom: 46rem;
    }

    .Courses_cards .Conteiner-div01 .main {
        margin-bottom: 35px;
    }

    .Courses_cards .Conteiner-div02 {
        display: flex;
        flex-direction: column;
        margin-bottom: 38.5em;
    }

    .Courses_cards .Conteiner-div02 .main {
        margin-bottom: 35px;
    }

    .Courses_cards .Conteiner-div03 {
        flex-direction: column;
        margin-bottom: 17.5em;
    }

    .Courses_cards .Conteiner-div03 .main {
        margin-bottom: 35px;
    }

    .Conteiner-3 {
        margin: 0 2%;
    }

    .Conteiner-3 .Skill {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .placement_support,
    .hourse_activity {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .Skill p {
        margin: auto;
        width: 90%;
        text-align: justify;
        display: flex;
        align-items: center;
    }

    .Conteiner-div-2 {
        margin-top: 0;
        margin-bottom: 5%;
        font-size: 9px;
    }

    .Conteiner-div-2 .Sub-div {
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
    }

    footer {
        background-color: #1D2B53;
    }

    .footerContent .logoandContact .contact {
        margin-top: -30px;
    }

    .footerContent {
        margin-top: -60px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 10px;
    }

    .footerContent a,
    .footerContent ul {
        font-size: 15px;
    }

    .copyright {
        display: flex;
        flex-direction: column;
    }

    /* --------------------------------------------Contact Us-------------------------------------------- */
    .Contactus_Containers {
        font-size: 35px;
    }

    .Contactus_Container h1 {
        text-align: center;
        font-weight: bolder;
        font-size: 30px;
    }

    .from_container {
        display: flex;
        flex-direction: column-reverse;
    }

    form {
        width: 80%;
        padding: 20px;
        background-image: linear-gradient(347deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(175deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(372deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(380deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(223deg, rgb(226, 28, 52), rgb(226, 28, 52));
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    input,
    textarea {
        margin-left: -13px;
        width: 100%;
        padding: 12px;
        margin-bottom: 15px;
        border: 1px solid #ced4da;
        border-radius: 6px;
        background-color: #f8f9fa;
        outline: none;
        transition: border-color 0.3s ease;
    }

    iframe {
        margin: 2% auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 150px;
        margin-left: -10px;
    }

    .illustration {
        display: none;
    }

    /* ------------------------------All Courses------------------------------ */
    .Courses_table {
        margin: 5%;
        width: 90%;
        font-size: 12px;
        font-weight: bolder;
    }

    .next_page_link h1 {

        font-size: small;
        text-align: center;
    }

    .Courses_table tbody td {
        padding: 5px;
    }

    .Collapsible details {
        font-size: 12px;
    }

    table {
        width: 100%;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border: 1px solid #ccc;
    }

    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    .coursesName {
        font-size: 20px;
    }

    td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-column);
        color: #000;
        font-weight: bold;
    }

    /* ---------------------------------------AboutUs--------------------------------------- */
    .aboutus {
        padding: 20px;
        width: auto;
    }


    .VisionandMission {
        width: auto;
        margin-left: 0;
        padding: 20px;
    }

    .ManagementTeam {
        padding: 20px;
        width: auto;
    }

    .aboutus h1,
    .VisionandMission h1,
    .ManagementTeam h1 {
        text-align: justify;
        font-size: 30px;
        color: rgb(197, 46, 46);
    }
}

@media only screen and (max-width: 300px) {
    .text {
        font-size: 11px;
    }
}