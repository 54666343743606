@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Sulphur Point', sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    scroll-behavior: smooth;
}

.mySlides img {
    margin: auto;
    height: 300px;
    width: 1200px;
}

.slideshow-container {
    margin: auto;
    max-width: 1200px;
    position: relative;
    margin: auto;
}

.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot {
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

a {
    text-decoration: none;
    color: black;
}

.dropdown ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dropdown ul h1 {
    color: black;
}

.dropdown ul li {
    margin: 10px;
    color: #a2a2a2;
}

.dropdown ul li:hover {
    color: rgb(0, 0, 0);
    font-size: 18px;
    transition: ease-in 0.1s;
    margin: 9.4px;
}

.dropdown .technologies,
.dropdown .markets {
    width: 20em;
    text-align: left;
}

.dropdown li {
    margin-right: 20px;
    color: #fff;
    cursor: pointer;
}

.dropdown {
    padding: 50px;
    list-style-type: none;
    background-color: #fffffff9;
    border-radius: 5px;
    color: white;
    position: absolute;
    width: 1200px;
    top: 10%;
    left: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
}

.welcome_to_ITA {
    margin: 10px auto;
    width: 1200px;
    color: rgb(0, 0, 0);
}

.welcome_to_ITA h1,
.welcome_to_ITA p {
    padding: 10px;
}

.frontpage_cards {
    padding: 50px 0;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 15px;

}

.frontpage_cards .forntpage_card {
    background-color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.frontpage_cards .forntpage_card:hover {
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(92deg, rgb(226, 28, 52), rgb(226, 28, 52));
    color: white;
    border: none;

}

.frontpage_cards .forntpage_card p {
    padding: 10px;
}

.empower_para {
    margin: auto;
    padding: 20px;
    width: 1200px;
    text-align: center;
}

.key_features {
    position: relative;
    margin: 1% auto;
    margin-bottom: 5%;
    width: 1200px;
    display: grid;
    grid-template-columns: 60% 30%;
    place-items: center;
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(92deg, rgb(226, 28, 52), rgb(226, 28, 52));
    color: white;
    border-radius: 10px;
    padding: 50px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.key_features_left {
    padding: 0 30px;
    width: 60%;
}

.key_features_left .heading_and_para {
    padding: 10px 5px;
    text-align: justify;
}

.key_features_left .heading_and_para p {
    font-size: larger;
}

.key_features .key_features_card {
    position: absolute;
    padding: 30px;
    width: 25em;
    height: 500px;
    margin-top: -15em;
    margin-left: -10em;
    border-radius: 5px;
    display: grid;
    place-items: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(8, 4, 4, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
}

.key_features .key_features_card p{
    margin-bottom: -15px;
}

.cheers img {
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10px;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.job_orientation {
    margin: 2% auto;
    width: 1200px;
    text-align: center;
}

.Job_orientation_container {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
}

.Job_orientation_container .swippercard {
    margin: 10px;
    padding: 10px;
    display: grid;
    place-items: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    text-align: center;
}

.Job_orientation_container .swippercard img {
    width: 100px;
}

.Job_orientation_container .swippercard hr {
    width: 50%;
}

.Job_orientation_container .swippercard p {
    text-align: justify;
    padding: 10px 45px;
}

.test {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.test:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(98, 98, 98, 0.761);
}

.overlay_text {
    font-family: 'Sulphur Point', sans-serif;
    font-size: 2em;
    color: white;
    text-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.5);
    margin: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.swippercard .card_btns {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.swippercard .card_btns button {
    padding: 8px 10px;
    border-radius: 5px;
}

.swippercard .card_btns .learn_more {
    background-color: rgb(132, 132, 148);
    border: none;
}

.swippercard .card_btns .learn_more a {
    text-decoration: none;
    color: white;
}

.swippercard .card_btns .apply {
    background-color: rgb(65, 176, 255);
    border: none;
}

.swippercard .card_btns .apply {
    background-color: rgb(255, 117, 3);
    color: white;
    border: none;
}

.carousel-container {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    min-width: 100%;
    box-sizing: border-box;
}

#prevBtn {
    left: 0;
}

#nextBtn {
    right: 0;
}

.our_legacy {
    margin: 2% auto;
    width: 1200px;
    background-image: linear-gradient(347deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(175deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(372deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(380deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(223deg, rgb(29, 43, 83), rgb(29, 43, 83));
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.our_legacy h1 {
    padding: 20px;
    color: rgb(236, 236, 236);
}

.legacy_container {
    padding: 0 10px;
    text-align: center;
    margin-bottom: 50px;
}

.legacy_container h1 {
    font-size: 15px;
}

.legacy_main {
    display: grid;
    grid-template-columns: 60% 30%;
    place-items: center;
    place-items: center;
    padding: 0 20px;
}

.our_legacy_image {
    margin-left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer {
    margin: auto;
    padding: 2.5em 0;
    width: 100vw;
    font-weight: 900;
    background-color: #1D2B53;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.footerContent {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footerContent h1 {
    color: #ffffff;
}

.footerContent a {
    color: rgb(255, 255, 255);
    font-weight: 100;
    font-size: 20px;
    font-weight: bold;
}

.footerContent ul {
    list-style: none;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: bold;
}

.footerContent a:hover,
.footerContent li:hover {
    color: #007aff;
}

.footerContent .logoandContact .contact {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerContent .logoandContact .contact img {
    padding: 10px;
}

footer .copyright {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    color: rgb(255, 255, 255);
    font-weight: 100;
}

footer .copyright .toparrow a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: bold;
}


/* --------------------------------------------------------About Us-------------------------------------------------------- */
.aboutus {
    margin: auto;
    width: 1200px;
    text-align: justify;
}

.aboutus h1,
.VisionandMission h1,
.ManagementTeam h1 {
    text-align: justify;
    font-size: 50px;
    color: grey;
}

.aboutdropdown {
    padding: 50px;
    list-style-type: none;
    background-color: #fffffff9;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    position: absolute;
    width: 1200px;
    top: 10%;
    left: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
}

.VisionandMission {
    margin-top: 5%;
    margin-left: 20%;
    margin-bottom: 24.3em;
    width: 1200px;
    text-align: justify;
    font-size: 14.5px;
}

.ManagementTeam {
    margin: 2.5% auto;
    width: 1200px;
    text-align: justify;
    font-size: 14.5px;
}

/* --------------------------------------------------------Contact Us-------------------------------------------------------- */

.Contactus_Container {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    color: #495057;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Contactus_Container h1 {
    text-align: center;
    font-weight: bolder;
    font-size: 50px;
}

form {
    width: 500px;
    padding: 20px;
    background-image: linear-gradient(347deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(175deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(372deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(380deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(223deg, rgb(226, 28, 52), rgb(226, 28, 52));
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

form button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    width: 50%;
    border-radius: 5px;
    border: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input,
textarea {
    width: 95%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    background-color: #f8f9fa;
    outline: none;
    transition: border-color 0.3s ease;
}

input:focus,
textarea:focus {
    border-color: #235391;
}

.from_container {
    margin: 2.6% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.from_content {
    margin: 10px;
    padding: 5px;
    text-align: justify;
    font-weight: bold;
    color: white;
}

iframe {
    margin: 2% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 450px;
}

.Courses_table {
    margin: 3.2% auto;
    width: 70%;
    font-weight: bold;
}

.Courses_table thead {
    background-image: linear-gradient(to bottom right, #E21C34, #500B28);
    color: white;
    font-weight: 100;
}

.Courses_table thead th:nth-child(1) {
    border-radius: 10px 0px 0px 0px;
}

.Courses_table thead th:nth-child(5) {
    border-radius: 0px 10px 0px 0px;
}

.next_page_link h1 {
    color: rgb(0, 0, 0);
}


.Courses_table a {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    margin-top: 20px;
}

.Courses_table a:hover {
    color: rgb(241, 40, 40);
    transition: .3s ease all;
    border-bottom: 1px solid rgb(241, 40, 40);
}

.Courses_table tbody tr {
    border-left: 5px solid #E21C34;
    border-right: 5px solid #500C28;
    border-bottom: 1px solid #bcbcbc;
}

.Courses_table tbody td {
    padding: 10px;
    text-align: left;
}

.Courses_table tbody td button {
    padding: 5px 30px;
    text-align: center;

}
