.ViewMore {
  margin: auto;
  max-width: 1200px;
}

.Conteiner-1 {
  margin-top: 50px;
  color: white;
  display: grid;
  text-align: center;
  place-items: center;
  padding: 50px 0;
  background: url("../Assets/ITA\ 3\ Banner-02.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.start-div {
  margin: auto;
  width: 90%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.result {
  text-align: justify;
  padding: 2px 1px;
}

.start-div div:nth-child(1) {
  text-align: end;
  border-right: 2px solid gray;
  padding: 2px 10px;
}

.start-div div h2 {
  color: yellow;
}

.star {
  color: yellow;
}

.Conteiner-1 button {
  margin: 10px 0;
  padding: 10px 60px;
  border: none;
  color: #235391;
  font-weight: bold;
  border-radius: 5px;
}

.action-button a {
  padding: 10px;
  color: white;
  border-bottom: 1px dashed;
  font-size: 15px;
  font-weight: 100px;
}

.action-button a:hover {
  border-bottom: 2px solid;
}

.action-button {
  font-size: 20px;
  padding: 20px;
  color: white;
}

/* Conteiner-2 */

.Conteiner-2 {
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 2% 0;
}

.FeaturesContainer .KeyFeatures01,
.FeaturesContainer .KeyFeatures02 {
  padding: 20px 5px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: large;
}

.KeyFeatures01 div,
.KeyFeatures02 div {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.KeyFeatures-icon {
  padding: 10px;
  color: #E21C34;
}

.KeyFeaturesMainContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Conteiner-3 */
.Conteiner-3 {
  margin: 3% 5%;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
}

.Conteiner-3 .Skill {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.Conteiner-3 h2 {
  padding: 10px 0;
  font-weight: bold;
  color: grey;
}

.Conteiner-3 p {
  display: flex;

  gap: 10px;
  text-align: center;
}

.Conteiner-div-2 {
  display: flex;
  justify-content: center;
  text-align: center;
  justify-content: space-around;
  width: 100%;
  color: #f0f0f0;
  background-image: linear-gradient(to bottom right, #E21C34, #500B28);
}

.Sub-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.Conteiner-4 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background: rgba(220, 220, 220, 0.316);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 4%;
}

.Conteiner-4 h2 {
  color: crimson;
}

.Conteiner-4 p {
  display: flex;
  align-items: center;
}

.CheckIcon {
  color: crimson;
  padding: 0px 10px;
}

/* Conteiner-5 */
.Conteiner-5 .Graphs_Container {

  display: flex;
}

.Graphs_Container img {
  width: 100%;
  height: 400px;
}


/* Conteiner-6 */
.Conteiner-6 h1 {
  text-align: center;
}

.Courses_cards .Conteiner-div01,
.Courses_cards .Conteiner-div02,
.Courses_cards .Conteiner-div03 {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-Sub {
  position: relative;
  width: 50%;
  height: 150px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: rgb(150, 141, 141);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.container-Sub:hover .overlay {
  height: 100%;
  background-image: linear-gradient(to bottom right, #E21C34, #500B28);
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Conteiner-7 */
.Conteiner-7 {
  padding: 20px;
  display: grid;
  margin-top: 5%;
}

.Conteiner-7 h3 {
  color: #007bff;
}

.Conteiner-7 p {
  margin-bottom: 20px;
}

.accordion-item {

  justify-content: center;
  text-align: start;
  overflow: hidden;
}

.accordion-content {
  padding: 5px 10px;
}

.accordion-header {
  background: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 10px 10px 20px gray;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bolder;
}

.accordion-list {
  overflow: hidden;
  height: 100%;
}

.head1 {
  color: red;
}

.accordion-head {
  font-weight: bold;
}

.h5li1 {
  color: rebeccapurple;
}

.main_container {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  padding: 5%;
}

.card {
  width: 19em;
  height: 12em;
  background-color: #252525;
  border-radius: 7px;
  cursor: pointer;
}

.fullscreen {
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
}

.card_back {
  position: absolute;
  width: 20em;
  height: 15em;
  background-image: linear-gradient(373deg, rgba(228, 228, 228, 0.04) 0%, rgba(228, 228, 228, 0.04) 30%, rgba(130, 130, 130, 0.04) 30%, rgba(130, 130, 130, 0.04) 49%, rgba(31, 31, 31, 0.04) 49%, rgba(31, 31, 31, 0.04) 100%), linear-gradient(387deg, rgba(228, 228, 228, 0.04) 0%, rgba(228, 228, 228, 0.04) 20%, rgba(130, 130, 130, 0.04) 20%, rgba(130, 130, 130, 0.04) 60%, rgba(31, 31, 31, 0.04) 60%, rgba(31, 31, 31, 0.04) 100%), linear-gradient(332deg, rgba(228, 228, 228, 0.04) 0%, rgba(228, 228, 228, 0.04) 29%, rgba(130, 130, 130, 0.04) 29%, rgba(130, 130, 130, 0.04) 48%, rgba(31, 31, 31, 0.04) 48%, rgba(31, 31, 31, 0.04) 100%), linear-gradient(180deg, rgb(229, 31, 46), rgb(229, 31, 46));
  border-radius: 7px;
  margin-top: -5em;
  margin-left: 0.7em;
  transition: .2s ease-in-out;
  z-index: -1;
}

.main:hover .card_back {
  margin-top: -4.15em;
  margin-left: -0.4em;
  scale: 1.1;
  height: 26.50em;
  cursor: pointer;
}

.main:hover .fl {
  opacity: 1;
  cursor: pointer;
  margin-right: 0.5em;
}


.data {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
}

.img {
  width: 1.25em;
  height: 1.25em;
  background-color: #26205b;
  border-radius: 5px;
  overflow: hidden;
}

.text {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Montserrat;
  margin-top: -2em;
}

.text_m h2 {
  font-weight: bold;
  font-size: 0.9em;
  color: #bcbcbc;
}


.btns {
  display: flex;
  gap: 0.5em;
  transition: .2s ease-in-out;
}

.likes {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  width: 19.5em;
  line-height: 15px;
  height: 13em;
  border-radius: 4px;
  margin-top: -1.5em;
  color: rgb(255, 255, 255);
  font-weight: 100;
  opacity: 0;
  transition: .5s ease-in-out;
  font-weight: bold;
}

.likes_text {
  font-family: Montserrat;
  font-size: 0.8em;
  margin-left: 0em;
  color: white;
}

.likes_svg {
  width: 12px;
  height: 1px;
  fill: white;
}

.comments {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 1.4em;
  border-radius: 4px;
  margin-top: -0.5em;
  opacity: 0;
  background-color: #444857;
  transition: .24s ease-in-out;
}

.comments_text {
  font-family: Montserrat;
  font-size: 0.8em;
  margin-left: 0.25em;
  color: white;
}

.comments_svg {
  width: 12px;
  height: 12px;
  fill: white;
}

.comments:hover {
  background-color: #5A5F73;
  cursor: pointer;
}

.main:hover .likes {
  margin-top: 0.5em;
  opacity: 1;
}

.main:hover .comments {
  margin-top: 0.5em;
  opacity: 1;
}

.main:hover .views {
  margin-top: 0.5em;
  opacity: 1;
}

.card_content .switch_738 img {
  position: absolute;
  top: -25px;
  left: -45px;
}



.card_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch_738 {
  font-size: 13px;
  position: relative;
  display: inline-block;
  width: 1.2em;
  height: 3.3em;
}

.Collapsible {
  margin: auto;
  margin-bottom: 2%;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #1e1e1e;
  font-weight: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.Collapsible h1 {
  color: black;
}

.Collapsible h2 {
  color: #040404;
  font-weight: bold;
}

.Collapsible details {
  background-image: linear-gradient(347deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(175deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(372deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(380deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(223deg, rgb(226, 28, 52), rgb(226, 28, 52));
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Collapsible details summary {
  padding: 10px;
  border-bottom: 1px solid rgb(203, 195, 195);
  color: white;
  font-weight: 100;
  font-size: 15px;
}

.Collapsible details ul {
  padding: 10px 15px;
  list-style: none;
  color: white;
  font-weight: 100;
}

.Collapsible details p {
  color: white;
  padding: 10px 15px;

}

details summary span {
  color: #ffffff;
  font-size: 15px;
}