.Loader_card {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    --bg-color: #212121;
    background-color: var(--bg-color);
    padding: 1rem 2rem;
  }
  
  .MainCard_loader {
    color: rgb(124, 124, 124);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    padding: 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }
  
  .words {
    overflow: hidden;
    position: relative;
    font-size: 2.5rem;
  }
  
  .words::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(var(--bg-color) 10%,
        transparent 30%,
        transparent 70%,
        var(--bg-color) 90%);
    z-index: 20;
  }
  
  .word {
    display: block;
    top: 10px;
    font-size: 25px;
    padding-left: 6px; 
    color: #ff0000; 
    animation: spin_4991 4s infinite;
  }
  
  @keyframes spin_4991 {
    10% {
      -webkit-transform: translateY(-102%);
      transform: translateY(-102%);
    }
  
    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  
    35% {
      -webkit-transform: translateY(-202%);
      transform: translateY(-202%);
    }
  
    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  
    60% {
      -webkit-transform: translateY(-302%);
      transform: translateY(-302%);
    }
  
    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
  
    85% {
      -webkit-transform: translateY(-402%);
      transform: translateY(-402%);
    }
  
    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }