.slideshow {
    position: relative;
    margin: 2% auto;
    width: 1200px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(92deg, rgb(226, 28, 52), rgb(226, 28, 52));
    border-radius: 10px;
}

.slideshow_text {
    position: absolute;
    top: 40%;
    left: 8%;
}

.head {
    display: grid;
    color: rgb(5, 5, 6);
    padding: 10px;
    font-size: 50px;
    font-weight: 500;
    border-left: 4px solid rgb(18, 18, 82);
    font-weight: bold;
}

.text_slideshow {
    position: absolute;
    left: 5%;
    font-size: 10px;
    font-weight: 500;
}

.text-slide {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    width: 400px;
    left: 24%;
    color: black;
}

.text-slide.active {
    opacity: 1;
    visibility: visible;
}

.text-slide p {
    font-size: large;
}

.slideshow img {
    position: relative;
    height: 35em;
    left: 50%;
    top: 7px;
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .slideshow {
        margin: 10% 22%;
        width: 100%;
        left: 20%;
        text-align: center;
        border-bottom: none;
    }

    .slideshow .head {
        width: 100%;
        font-size: 35px;
    }

    .text_slideshow {
        left: 0;
        font-size: 5px;
    }

    .slideshow img {
        display: none;
    }
}

@media screen and (min-width: 400px) and (max-width: 699px) {
    .slideshow {
        margin: 10% 30%;
        left: 25%;
        text-align: center;
        border-bottom: none;
    }

    .slideshow img {
        display: none;
    }
}

@media screen and (min-width: 299px) and (max-width: 399px) {

    .slideshow img {
        display: none;
    }

    .slideshow {
        border: none;
    }
} */